.a {
    background-image: url('https://image.freepik.com/fotos-gratis/fundo-gradiente-suave_77528-21.jpg');
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.b {
    background-image: url('https://image.freepik.com/fotos-gratis/abstrato-luxo-limpar-amarelo-parede-bem-usar-como-pano-de-fundo-fundo-e-layout_1258-266.jpg');
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.c {
    background-image: url('https://image.freepik.com/vetores-gratis/fundo-gradiente-brilhante-em-tons-de-verde_23-2148358650.jpg');
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.d {
    background-image: url('https://image.freepik.com/fotos-gratis/boletim-de-papel-template-suave_1258-167.jpg');
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.ant-card-meta-title {
    color: #ffffff;
    font-size: 200%;
    border: 0;
}

.ant-card-meta-avatar {
    color: #ffffff;
    font-size: 500%;
}

.ant-card-meta-description {
    color: #ffffff;
    font-size: 500%;
}

.login {
    background             : url(../../assets/login.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size   : cover;
    -o-background-size     : cover;
    background-size        : cover;
    height                 : 100vh;
}

.login .logo {
    padding: 40px 20px 30px;
    width: 80%;
    height: 80%;
    display: block;
    margin: 0 auto;
}

.login .ant-form-item {
    margin-bottom: 10px;
}

.login .ant-form-explain {
    display: none;
}

.login .ant-card {
    border-radius: 5%;
}
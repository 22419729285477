.caption-item {
	display: flex;
	margin-bottom: 1%;
	padding-right: 2%;
}

.caption-color {
	width: 16px;
	height: 16px;
	margin-right: 8px;
	border-radius: 50%;
}

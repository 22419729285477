.fc .fc-bg-event {
  opacity: 0.5;
}

.fc .fc-bg-event .fc-event-title {
  font-style: normal;
  font-weight: bold;
}

/* .fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
} */

/*.fc .fc-col-header-cell-cushion { /* needs to be same precedence * /
  padding-top: 5px; /* an override! * /
  padding-bottom: 5px; /* an override! * /
}*/

/* :root { */
  /* --fc-small-font-size: 0,85em; */
  /* --fc-page-bg-color: #fff; */
  /* --fc-neutral-bg-color: rgba(208, 208, 208, 0,3); */
  /* --fc-neutral-text-color: #808080; */
  /* --fc-border-color: #ddd; */

  /* --fc-button-text-color: #fff; */
  /* --fc-button-bg-color: #2C3E50; */
  /* --fc-button-border-color: #2C3E50; */
  /* --fc-button-hover-bg-color: #1e2b37; */
  /* --fc-button-hover-border-color: #1a252f; */
  /* --fc-button-active-bg-color: #1a252f; */
  /* --fc-button-active-border-color: #151e27; */

  /* --fc-event-bg-color: #3788d8; */
  /* --fc-event-border-color: #3788d8; */
  /* --fc-event-text-color: #fff; */
  /* --fc-event-selected-overlay-color: rgba(0, 0, 0, 0,25); */

  /* --fc-more-link-bg-color: #d0d0d0; */
  /* --fc-more-link-text-color: herdar; */

  /* --fc-event-resizer-thickness: 8px; */
  /* --fc-event-resizer-dot-total-width: 8px; */
  /* --fc-event-resizer-dot-border-width: 1px; */

  /* --fc-non-business-color: rgba(215, 215, 215, 0,3); */
  /* --fc-bg-event-color: rgb(143, 223, 130); */
  /* --fc-bg-event-opacity: 0,3; */
  /* --fc-realce-color: rgba(188, 232, 241, 0,3); */
  /* --fc-today-bg-color: rgba(255, 220, 40, 0,15); */
  /* --fc-now-indicator-color: vermelho; */

  /* --fc-list-event-dot-width: 10px; */
  /* --fc-list-event-hover-bg-color: #f5f5f5; */
/* } */
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,100&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-pro-global-header {
  position: relative;
  height: 64px;
  padding: 0;
  background: #0a1b30;
  /* box-shadow: 0 1px 4px rgba(3, 0, 41, 0.08); */
}

.antd-pro-components-global-header-index-right {
  float: right;
  height: 100%;
  overflow: hidden;
}

.ant-page-header-heading-title {
  display: block;
  float: left;
  margin-bottom: 0;
  padding-right: 12px;
  color: rgba(0,0,0,0.85);
  font-weight: 600;
  font-size: 24px;
  padding-left: 15px;
}

.ant-modal-header{
  background: #1e1e1e !important;
}

.ant-modal-title {
  color: #ffffff !important;
  font-weight: bold !important;
}

.ant-modal-close-x {
  color: #ffffff !important;
}

.ant-menu-submenu > .ant-menu {
  background-color: #EEEEEE !important;
}

.expiredDate {
  color: #ad3434;
}

.denied {
  color: #04061c;
}

.pending {
  color: #de7504;
}

.approved {
  color: #3bbf5e;
}

.reversal {
  color: #7701fd;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: currentColor;
}


.anticon.anticon-left.slick-arrow.slick-prev {
    font-size: 27px;
}

.anticon.anticon-right.slick-arrow.slick-next {
    font-size: 27px;
}
.login {
    background             : url(/static/media/login.4d42e4e8.jpg) no-repeat center center fixed;
    background-size        : cover;
    height                 : 100vh;
}

.login .logo {
    padding: 40px 20px 30px;
    width: 80%;
    height: 80%;
    display: block;
    margin: 0 auto;
}

.login .ant-form-item {
    margin-bottom: 10px;
}

.login .ant-form-explain {
    display: none;
}

.login .ant-card {
    border-radius: 5%;
}
.fab-button {
  position  : fixed;
  bottom    : 16px;
  right     : 16px;
  width     : 56px !important;
  height    : 56px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  font-size : 24px !important;
  z-index   : 1000;
}
.a {
    background-image: url('https://image.freepik.com/fotos-gratis/fundo-gradiente-suave_77528-21.jpg');
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.b {
    background-image: url('https://image.freepik.com/fotos-gratis/abstrato-luxo-limpar-amarelo-parede-bem-usar-como-pano-de-fundo-fundo-e-layout_1258-266.jpg');
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.c {
    background-image: url('https://image.freepik.com/vetores-gratis/fundo-gradiente-brilhante-em-tons-de-verde_23-2148358650.jpg');
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.d {
    background-image: url('https://image.freepik.com/fotos-gratis/boletim-de-papel-template-suave_1258-167.jpg');
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.ant-card-meta-title {
    color: #ffffff;
    font-size: 200%;
    border: 0;
}

.ant-card-meta-avatar {
    color: #ffffff;
    font-size: 500%;
}

.ant-card-meta-description {
    color: #ffffff;
    font-size: 500%;
}

.event-caption {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 1%;
}

.caption-item {
	display: flex;
	align-items: center;
	margin-bottom: 1%;
	padding-right: 2%;
}

.caption-color {
	width: 16px;
	height: 16px;
	margin-right: 8px;
	border-radius: 50%;
}

.fc .fc-bg-event {
  opacity: 0.5;
}

.fc .fc-bg-event .fc-event-title {
  font-style: normal;
  font-weight: bold;
}

/* .fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
} */

/*.fc .fc-col-header-cell-cushion { /* needs to be same precedence * /
  padding-top: 5px; /* an override! * /
  padding-bottom: 5px; /* an override! * /
}*/

/* :root { */
  /* --fc-small-font-size: 0,85em; */
  /* --fc-page-bg-color: #fff; */
  /* --fc-neutral-bg-color: rgba(208, 208, 208, 0,3); */
  /* --fc-neutral-text-color: #808080; */
  /* --fc-border-color: #ddd; */

  /* --fc-button-text-color: #fff; */
  /* --fc-button-bg-color: #2C3E50; */
  /* --fc-button-border-color: #2C3E50; */
  /* --fc-button-hover-bg-color: #1e2b37; */
  /* --fc-button-hover-border-color: #1a252f; */
  /* --fc-button-active-bg-color: #1a252f; */
  /* --fc-button-active-border-color: #151e27; */

  /* --fc-event-bg-color: #3788d8; */
  /* --fc-event-border-color: #3788d8; */
  /* --fc-event-text-color: #fff; */
  /* --fc-event-selected-overlay-color: rgba(0, 0, 0, 0,25); */

  /* --fc-more-link-bg-color: #d0d0d0; */
  /* --fc-more-link-text-color: herdar; */

  /* --fc-event-resizer-thickness: 8px; */
  /* --fc-event-resizer-dot-total-width: 8px; */
  /* --fc-event-resizer-dot-border-width: 1px; */

  /* --fc-non-business-color: rgba(215, 215, 215, 0,3); */
  /* --fc-bg-event-color: rgb(143, 223, 130); */
  /* --fc-bg-event-opacity: 0,3; */
  /* --fc-realce-color: rgba(188, 232, 241, 0,3); */
  /* --fc-today-bg-color: rgba(255, 220, 40, 0,15); */
  /* --fc-now-indicator-color: vermelho; */

  /* --fc-list-event-dot-width: 10px; */
  /* --fc-list-event-hover-bg-color: #f5f5f5; */
/* } */
.caption-item {
	display: flex;
	margin-bottom: 1%;
	padding-right: 2%;
}

.caption-color {
	width: 16px;
	height: 16px;
	margin-right: 8px;
	border-radius: 50%;
}


.fab-button {
  position  : fixed;
  bottom    : 16px;
  right     : 16px;
  width     : 56px !important;
  height    : 56px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  font-size : 24px !important;
  z-index   : 1000;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,100&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-pro-global-header {
  position: relative;
  height: 64px;
  padding: 0;
  background: #0a1b30;
  /* box-shadow: 0 1px 4px rgba(3, 0, 41, 0.08); */
}

.antd-pro-components-global-header-index-right {
  float: right;
  height: 100%;
  overflow: hidden;
}

.ant-page-header-heading-title {
  display: block;
  float: left;
  margin-bottom: 0;
  padding-right: 12px;
  color: rgba(0,0,0,0.85);
  font-weight: 600;
  font-size: 24px;
  padding-left: 15px;
}

.ant-modal-header{
  background: #1e1e1e !important;
}

.ant-modal-title {
  color: #ffffff !important;
  font-weight: bold !important;
}

.ant-modal-close-x {
  color: #ffffff !important;
}

.ant-menu-submenu > .ant-menu {
  background-color: #EEEEEE !important;
}

.expiredDate {
  color: #ad3434;
}

.denied {
  color: #04061c;
}

.pending {
  color: #de7504;
}

.approved {
  color: #3bbf5e;
}

.reversal {
  color: #7701fd;
}
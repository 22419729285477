.event-caption {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 1%;
}

.caption-item {
	display: flex;
	align-items: center;
	margin-bottom: 1%;
	padding-right: 2%;
}

.caption-color {
	width: 16px;
	height: 16px;
	margin-right: 8px;
	border-radius: 50%;
}
